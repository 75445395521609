import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Img from 'gatsby-image';

import { getRightQueryForLanguage } from '../../utils/getRightQueryForLanguage';

import Watermark from '../../assets/watermark.svg';

import Link from '../Link';

const Footer = ({ language }) => {
  const {
    allMarkdownRemark: { edges },
    background,
    logoLg
  } = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "hidden" } } }
      ) {
        edges {
          node {
            frontmatter {
              footer {
                imprint
                privacy
                title
                contact {
                  email
                  telephone
                }
                bottomBadge
              }
              language
            }
          }
        }
      }
      background: file(name: { regex: "/footer-bg/" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      logoLg: file(name: { regex: "/logo-lg-white/" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const { footer } = getRightQueryForLanguage(language, edges);

  const { title, imprint, privacy, contact, bottomBadge } = footer;
  const { email, telephone } = contact;

  return (
    <footer className="bg-black-300 relative">
      <div className="absolute top-0 left-0 w-full h-full">
        <Img
          fluid={background.childImageSharp.fluid}
          alt="giant carensol logo"
          className="object-cover h-full"
        />
      </div>
      <div className="container mx-auto max-w-xl relative py-8 px-4 space-y-4">
        <div className="flex flex-col space-y-4">
          <h3 className="footer-item-text-sm text-white-200">{title}</h3>
          <div className="flex text-white-300 justify-between items-center">
            <div className="flex flex-col space-y-2 footer-info-text-sm">
              <p>{email}</p>
              <p>{telephone}</p>
            </div>
            <a
              href="https://complex-dynamics.com"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Watermark} alt="Complex dynamics watermark" />
            </a>
          </div>
        </div>
        <div className="w-full border border-primary-200" />
        <div className="flex justify-between items-center space-x-8">
          <div className="w-1/2 h-full">
            <Img
              fluid={logoLg.childImageSharp.fluid}
              alt="carensol logo with lettering"
              className="object-cover h-full w-full"
            />
          </div>
          <div className="flex flex-col footer-item-text-sm text-right space-y-4 text-white-200 sm:flex-row sm:space-y-0 sm:space-x-8 sm:items-center">
            <Link to="/imprint" language={language}>
              {imprint}
            </Link>
            <Link to="/policy" language={language}>
              {privacy}
            </Link>
          </div>
        </div>
      </div>
      <div
        className="w-full py-2 relative text-white-100"
        style={{ background: '#171717' }}
      >
        <p className="sm:footer-copyright-text footer-copyright-text-sm text-center">
          {bottomBadge}
        </p>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  language: PropTypes.string.isRequired
};

export default Footer;
