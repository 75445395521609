import React from 'react';
import { useLocation } from '@reach/router';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import CookieConsent from 'react-cookie-consent';
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';

import { getRightQueryForLanguage } from '../../utils/getRightQueryForLanguage';

const CookieBanner = ({ language }) => {
  const location = useLocation();
  const {
    allMarkdownRemark: { edges }
  } = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "hidden" } } }
      ) {
        edges {
          node {
            frontmatter {
              cookieBanner {
                text
                decline
                accept
              }
              language
            }
          }
        }
      }
    }
  `);

  const { cookieBanner } = getRightQueryForLanguage(language, edges);

  const { text, accept, decline } = cookieBanner;

  return (
    <CookieConsent
      enableDeclineButton
      onDecline={() => {}}
      onAccept={() => {
        initializeAndTrack(location);
      }}
      location="bottom"
      buttonText={accept}
      declineButtonText={decline}
      cookieName="gatsby-gdpr-google-analytics"
      disableButtonStyles
      containerClasses="bg-white-200 shadow-xl z-50 w-full flex justify-between py-2 px-4 sm:py-4 sm:px-16 md:px-32 xl:px-64 lg:py-8 items-center space-x-8"
      buttonWrapperClasses="flex flex-col justify-center space-y-4 sm:flex-row sm:space-y-0"
      declineButtonClasses="md:px-12 py-2 px-8 focus:outline-none text-black-100"
      contentClasses="card-text-sm text-black-300 "
      disableStyles
      buttonClasses="bg-primary-200 rounded-full md:px-12 py-2 px-8 text-white-100 focus:outline-none"
    >
      {text}
    </CookieConsent>
  );
};

CookieBanner.propTypes = {
  language: PropTypes.string.isRequired
};

export default CookieBanner;
