import React from 'react';
import PropTypes from 'prop-types';
import { Link as GatsbyLink } from 'gatsby';

const Link = ({ to, language, children, className }) => (
  <GatsbyLink
    to={`${language === 'de' ? '' : `/${language}`}${to}`}
    className={className}
  >
    {children}
  </GatsbyLink>
);

Link.propTypes = {
  to: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.node.isRequired
};

export default Link;
