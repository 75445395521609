import React, { useState } from 'react';
import { Link as GatsbyLink, useStaticQuery, graphql } from 'gatsby';
import { Menu, Close } from '@material-ui/icons';
import PropTypes from 'prop-types';

import { getRightQueryForLanguage } from '../../utils/getRightQueryForLanguage';
import { PageRoutes } from '../../data/constants';

import De from '../../assets/de.svg';
import En from '../../assets/en.svg';

import Link from '../Link';

const Navbar = ({ activePage, language }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const { allMarkdownRemark, logoSm, logoMd } = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "hidden" } } }
      ) {
        edges {
          node {
            frontmatter {
              navbar {
                activities
                references
                aboutUs
                contact
                imprint
                privacy
              }
              language
            }
          }
        }
      }
      logoSm: file(name: { regex: "/logo-sm-color/" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      logoMd: file(name: { regex: "/logo-md-color/" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
    }
  `);

  const { edges } = allMarkdownRemark;

  const { navbar } = getRightQueryForLanguage(language, edges);

  const { activities, references, aboutUs, imprint, privacy } = navbar;

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const getActiveLinkClassName = (localRoute) =>
    activePage === localRoute ? 'navbar-item-active-text text-blue-400' : '';

  const getActiveLinkMobileClassName = (localRoute) =>
    activePage === localRoute ? 'navbar-item-active-text-sm text-blue-400' : '';

  return (
    <nav className="fixed top-0 left-0 w-full z-40 bg-white-100">
      <div className="relative flex py-2 sm:py-4 justify-between px-4 container items-center mx-auto z-50">
        <Link to="/" language={language}>
          <img
            src={logoSm.childImageSharp.fluid.src}
            alt="carensol logo small"
            className="sm:hidden h-10"
          />
          <img
            src={logoMd.childImageSharp.fluid.src}
            alt="carensol logo medium"
            className="hidden sm:block h-10"
          />
        </Link>
        <button
          className="relative focus:outline-none sm:hidden"
          onClick={toggleSidebar}
        >
          {isSidebarOpen ? <Close /> : <Menu />}
        </button>
        <div className="hidden sm:flex items-center space-x-4 lg:space-x-8 navbar-item-text text-black-100">
          <Link
            to={PageRoutes.ACTIVITIES}
            language={language}
            className={getActiveLinkClassName(PageRoutes.ACTIVITIES)}
          >
            {activities}
          </Link>
          <Link
            to={PageRoutes.REFERENCES}
            language={language}
            className={getActiveLinkClassName(PageRoutes.REFERENCES)}
          >
            {references}
          </Link>
          <Link
            to={PageRoutes.ABOUT_US}
            language={language}
            className={getActiveLinkClassName(PageRoutes.ABOUT_US)}
          >
            {aboutUs}
          </Link>
        </div>
        <div className="items-center space-x-4 hidden sm:flex">
          <GatsbyLink to="/en">
            <img src={En} alt="great britain flag" />
          </GatsbyLink>
          <GatsbyLink to="/">
            <img src={De} alt="germany flag" />
          </GatsbyLink>
        </div>
      </div>
      <div
        className={`w-full overflow-hidden transition-all duration-1000 flex flex-col absolute top-0 left-0 bg-white-100 z-40 justify-between ${
          isSidebarOpen ? 'h-screen' : 'h-0'
        }`}
      >
        <div className="flex flex-col justify-center relative items-center space-y-8 navbar-item-text-sm text-black-100 py-32">
          <Link
            to={PageRoutes.ACTIVITIES}
            language={language}
            className={getActiveLinkMobileClassName(PageRoutes.ACTIVITIES)}
          >
            {activities}
          </Link>
          <Link
            to={PageRoutes.REFERENCES}
            language={language}
            className={getActiveLinkMobileClassName(PageRoutes.REFERENCES)}
          >
            {references}
          </Link>
          <Link
            to={PageRoutes.ABOUT_US}
            language={language}
            className={getActiveLinkMobileClassName(PageRoutes.ABOUT_US)}
          >
            {aboutUs}
          </Link>
        </div>
        <div className="py-16 justify-center flex flex-col items-center space-y-8">
          <div className="items-center space-x-4 flex">
            <GatsbyLink to="/en">
              <img src={En} alt="great britain flag" />
            </GatsbyLink>
            <GatsbyLink to="/">
              <img src={De} alt="germany flag" />
            </GatsbyLink>
          </div>
          <div className="flex justify-center navbar-item-text text-black-100 space-x-4 w-full">
            <Link
              to="/imprint"
              className="w-1/2 text-right"
              language={language}
            >
              {imprint}
            </Link>
            <div style={{ width: '1px' }} className="bg-black-100 h-full" />
            <Link to="/policy" language={language} className="w-1/2">
              {privacy}
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

Navbar.propTypes = {
  activePage: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired
};

export default Navbar;
