import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { EmailOutlined } from '@material-ui/icons';

import { getRightQueryForLanguage } from '../../utils/getRightQueryForLanguage';

const LetsTalkBanner = ({ language }) => {
  const {
    allMarkdownRemark: { edges },
    file
  } = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "hidden" } } }
      ) {
        edges {
          node {
            frontmatter {
              language
              letsTalkBanner {
                title
                subtitle
                text
                buttonText
              }
            }
          }
        }
      }
      file(name: { regex: "/letsTalk-banner/" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
            src
          }
        }
      }
    }
  `);

  const { letsTalkBanner } = getRightQueryForLanguage(language, edges);

  const { title, subtitle, text, buttonText } = letsTalkBanner;

  return (
    <section className="bg-primary-200 py-8 text-white-100 sm:py-12 relative">
      <div className="absolute h-full w-full top-0">
        <Img
          className="object-cover h-full w-full"
          fluid={file.childImageSharp.fluid}
          alt="carensol lettering"
        />
      </div>
      <div className="container mx-auto px-4 flex flex-col space-y-2 relative items-start md:flex-row md:justify-between md:items-center">
        <div className="flex flex-col space-y-2">
          <h2 className="contact-heading-sm sm:contact-heading">{title}</h2>
          <h5 className="contact-subtitle-sm sm:contact-subtitle">
            {subtitle}
          </h5>
          <p className="contact-text-sm sm:contact-text">{text}</p>
        </div>
        <a
          className="flex space-x-2 items-center bg-white-200 rounded-full px-6 sm:px-8 py-1 sm:py-2 text-black-200"
          href="mailto:info@carensol.com"
        >
          <EmailOutlined />
          <p className="cta-text-light-sm sm:cta-text-light">{buttonText}</p>
        </a>
      </div>
    </section>
  );
};

LetsTalkBanner.propTypes = {
  language: PropTypes.string.isRequired
};

export default LetsTalkBanner;
