import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

const Image = ({ image, alt, className }) => {
  if (image.childImageSharp) {
    return (
      <Img
        fluid={image.childImageSharp.fluid}
        className={className}
        alt={alt}
      />
    );
  }

  return (
    <img src={image.replace(/\/static/g, '')} alt={alt} className={className} />
  );
};

Image.propTypes = {
  image: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.object
      })
    })
  ]).isRequired,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string
};

export default Image;
