import React from 'react';
import PropTypes from 'prop-types';

import Navbar from '../Navbar';
import Footer from '../Footer/template';
import SEO from '../SEO';
import LetsTalkBanner from '../LetsTalkBanner';
import CookieBanner from '../CookieBanner';

const Layout = ({ children, activePage, language, pageTitle }) => (
  <>
    <SEO title={pageTitle} lang={language} />
    <Navbar activePage={activePage} language={language} />
    <main>{children}</main>
    <LetsTalkBanner language={language} />
    <Footer language={language} />
    <CookieBanner language={language} />
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  activePage: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  pageTitle: PropTypes.string.isRequired
};

export default Layout;
